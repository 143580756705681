.headerContainer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 10px;
}

.siteMenuContainer {
    grid-column: 2; /* Center column */
    text-align: center;
}

.accountDiv {
    grid-column: 3; /* Right column */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.headerGoogleSigninButton,
.headerEmailSigninButton {
    display: inline-block;
    margin: 0 0.5%;
}

.siteLink {
    margin-left: 1rem;
    margin-right: 1rem;
}

.siteLinkDiv{
    display: inline-flex;
}