/* Search.module.css */

.searchDiv{
    margin-top: 7vh;
    margin-bottom: 13vh;
}

.searchLogoDiv{
    text-align: center;
    margin-bottom: 2rem;
}

.searchBarDiv {
    padding: 0% 20%;
    height: 5vh;
}

.searchSubmitButtonDiv {
    text-align: center;
    margin: 2.5% 0%;
}

.accordionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.circularProgress {
    margin-left: auto;
    margin-right: 2%;
}

.accordionDetailsDiv{
    text-align: left;
}

.metaSummaryCard {
    margin-bottom: 2%;
}

.metaSummaryResultDiv {
    text-align: left;
}

.tenderBaseDataResultDiv {
    text-align: center;
}