
/* Default styles */
.app-main {
  width: 90%; /* 90% of the parent container's width */
  margin: 0 3rem;
  padding: 2rem;
  text-align: center;
}

@media (min-width: 600px) {
  .container {
    width: 80%;
  }
}