.headerContainer {
    text-align: center;
}

.siteLink {
    color: grey;
    text-decoration-color: grey ;
}

.siteLinkDiv {
    margin-left: 1rem;
    margin-right: 1rem;
}

.FooterLinksDiv {
    display: inline-flex;
}

.DataAndTermsDiv {
    display: inline-flex;
    text-align: left;
    padding: 0% 10%;
}